"use client";

import Image from "next/image";
import { useElementSize } from "@mantine/hooks";
import { Variants, motion as m } from "framer-motion";

import type { Style } from "architecture/src/shared/domain/types";

import { AspectRatio } from "@/components/ui/surfaces/aspect-ratio";
import { Carrousel } from "architecture/src/shared/domain";

interface ScrollyTellingImageProps extends Carrousel {
  style?: Style;
}

const itemVariants: Variants = {
  "off-section": {
    position: "absolute",
  },
  "in-section": {
    position: "fixed",
  },
};

const useScrollyTellingImage = () => {
  const { ref, width } = useElementSize();

  return {
    ref,
    width,
    itemVariants,
  };
};

export const ScrollyTellingImage: React.FC<ScrollyTellingImageProps> = ({
  style,
  image,
  title,
}) => {
  const { ref, width, itemVariants } = useScrollyTellingImage();

  return (
    <div ref={ref} className="relative">
      <m.div
        style={{ width }}
        variants={itemVariants}
        className="overflow-hidden md:-translate-y-1/2 md:top-1/2 perspective rounded-xl"
      >
        <AspectRatio>
          <m.div
            style={{
              opacity: style?.opacity,
              scale: style?.scale,
            }}
            className="absolute inset-0 w-full h-full"
          >
            <Image
              sizes={"(min-width: 768px) 400px, 100vw"}
              fill
              src={image ?? ""}
              alt={title ?? ""}
            />
          </m.div>
        </AspectRatio>
      </m.div>
    </div>
  );
};
