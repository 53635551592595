import { create } from "zustand";

type RefContainer = React.RefObject<HTMLDivElement>;

interface KeyWordsState {
  refSection?: RefContainer;
  setRefSection: (_refSection: RefContainer) => void;
}

const keyWordsStore = create<KeyWordsState>()((set) => ({
  refSection: undefined,
  setRefSection: (refSection) => set(() => ({ refSection })),
}));

export const useKeywordsStore = keyWordsStore;
