import { cn } from "@/src/lib/utils";

export interface IRibbon {
  children: React.ReactNode;
  rotated?: boolean;
  title?: string;
}

const Title = ({ children }: { children: string }) => (
  <div
    className="text-xs text-center text-primary md:text-xl"
    dangerouslySetInnerHTML={{ __html: children }}
  />
);

export const Ribbon: React.FC<IRibbon> = ({
  children,
  rotated = false,
  title,
}) => {
  return (
    <div className="">
      <div
        className={cn(
          "flex flex-col gap-y-4 md:gap-y-6",
          rotated && "rotate-[-1deg] origin-bottom-left",
        )}
      >
        {title ? <Title>{title}</Title> : null}
        <div className="flex w-full h-20 md:h-32">{children}</div>
      </div>
    </div>
  );
};

export default Ribbon;
