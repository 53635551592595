import { VariantProps, cva } from "class-variance-authority";
import { forwardRef } from "react";

import { outfit, PlusJakarta, PlayfairDisplay } from "@/src/lib/fonts";

const variantMap: {
  [key: string]: React.ElementType;
} = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "h6",
  subtitle2: "h6",
  body1: "p",
  body2: "p",
  caption: "span",
};

type TypographyVariants = VariantProps<typeof typographyVariants>;

export const typographyVariants = cva("", {
  variants: {
    variant: {
      h1: "text-4xl md:text-6xl",
      h2: "text-3xl md:text-5xl",
      h3: "text-2xl md:text-4xl",
      h4: "text-xl md:text-3xl",
      h5: "text-xl md:text-2xl",
      h6: "text-base md:text-xl",
      subtitle1: "text-base md:text-xl",
      subtitle2: "text-sm md:text-lg",
      body1: "text-base",
      body2: "text-sm md:text-base",
      caption: "text-xs md:text-sm",
      overline: "text-xs",
    },
    align: {
      left: "text-left",
      center: "text-center",
      right: "text-right",
      justify: "text-justify",
      inherit: "",
    },
    color: {
      default: "text-foreground",
      primary: "text-primary-foreground",
      secondary: "text-secondary-foreground",
      accent: "text-accent-foreground",
      muted: "text-muted-foreground",
      inherit: "text-inherit",
    },
    fontFamily: {
      primary: outfit.className,
      secondary: PlusJakarta.className,
      tertiary: PlayfairDisplay.className,
    },
    weight: {
      light: "font-light",
      normal: "font-normal",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
    },
  },
  compoundVariants: [
    { variant: "h1", className: "font-bold" },
    { variant: ["h2", "h3", "h4"], className: "font-semibold" },
  ],
  defaultVariants: {
    variant: "body1",
    align: "inherit",
    color: "inherit",
    fontFamily: "primary",
  },
});

export interface ITypography
  extends Omit<React.HTMLAttributes<HTMLElement>, "color" | "style">,
    TypographyVariants {
  component?: React.ElementType;
  // style?: Style;
}

export const Typography = forwardRef(
  <T extends object>(
    {
      align,
      className,
      color,
      component,
      fontFamily,
      variant,
      weight,
      ...props
    }: T & ITypography,
    ref: React.ForwardedRef<HTMLHeadingElement>,
  ) => {
    const Component = component || variantMap[variant || "body1"];

    return (
      <Component
        ref={ref}
        className={typographyVariants({
          align,
          className,
          color,
          fontFamily,
          variant,
          weight,
        })}
        {...props}
      />
    );
  },
);

Typography.displayName = "Typography";

export default Typography;
