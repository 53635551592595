import { createQueryKeys } from "@lukemorales/query-key-factory";

import { HomeApiAdapter, HomeUseCase } from "architecture/features/home";

const homeAdapter = new HomeApiAdapter();
const { getHome } = new HomeUseCase(homeAdapter);

export const home = createQueryKeys("home", {
  all: {
    queryKey: null,
    queryFn: () => getHome(),
  },
});
