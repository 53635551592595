import {
  ContentType,
  SaveObjectResponse,
} from "architecture/src/shared/domain";

type ResultType =
  | "news/dapper_news"
  | "abc/dapper_abc"
  | "indicators/dapper_indicators"
  | "content/dapper_listening"
  | "regulations/dapper_regulations"
  | "projects/dapper_project"
  | "reports/dapper_reports"
  | "representant/political_resources"
  | "urbanactors/political_resources"
  | "events/dapper_schedule"
  | "content/dapper_highlights";

const slug: Record<ResultType, string> = {
  "news/dapper_news": "/dashboard/noticias",
  "abc/dapper_abc": "/dashboard/abc",
  "indicators/dapper_indicators": "/dashboard/indicadores",
  "content/dapper_listening": "/dashboard/polifonia",
  "regulations/dapper_regulations": "/dashboard/normativas",
  "projects/dapper_project": "/dashboard/regulacion/congreso/proyectos-de-ley",
  "reports/dapper_reports": "/dashboard/informes",
  "representant/political_resources":
    "/dashboard/regulacion/congreso/actores-del-congreso",
  "urbanactors/political_resources": "/dashboard/local/actores-urbanos",
  "events/dapper_schedule": "/dashboard/regulacion/congreso/debate-politico",
  "content/dapper_highlights": "",
};

interface ContentRedirectProps {
  contentType: ContentType;
  contentObject: SaveObjectResponse;
  id: string;
}

export function contentRedirect({
  contentType,
  id,
  contentObject,
}: ContentRedirectProps) {
  const { externalLink, document, gtype, city } = contentObject;
  const slugName = `${contentType.model}/${contentType.appLabel}`;
  let url = `${slug[slugName]}/${id}`;
  let openNewTab = false;

  if (gtype === "file") {
    url = document ?? "";
    openNewTab = true;
  } else if (gtype === "link" && externalLink) {
    url = externalLink;
    openNewTab = true;
  }

  if (city) {
    url = `${url}?ciudad=${city}`;
  }

  return {
    url,
    openNewTab,
  };
}
