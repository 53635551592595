import Image from "next/image";

import { RibbonWithLoop } from "@/components/ui/data-display/ribbon";
import { useLandingCompaniesGetSuspense as useLandingCompaniesGet } from "architecture/src/shared/api/endpoints/landing/landing";

export const Clients: React.FC<{}> = () => {
  const { data: companies } = useLandingCompaniesGet();

  if (companies.length === 0) return null;

  return (
    <section>
      <RibbonWithLoop baseVelocity={2} velocityOnHover={0.2}>
        {companies.map(({ image, title }) => (
          <Image
            key={title}
            src={image ?? ""}
            alt={title}
            width="0"
            height="0"
            sizes="(min-width: 768px) 240px, 80px"
            className="w-auto h-10 mx-3 md:mx-10 md:h-14 "
            priority
            //
          />
        ))}
      </RibbonWithLoop>
    </section>
  );
};
