import Image from "next/image";

import { Banner } from "architecture/src/shared/domain";

import { AspectRatio } from "@/components/ui/surfaces/aspect-ratio";

type HeroImageProps = Pick<Banner, "title" | "image">;

export const HeroImage: React.FC<HeroImageProps> = ({ image, title }) => {
  return (
    <div className="rounded-dot relative z-20 ">
      <AspectRatio>
        <Image
          src={image ?? ""}
          alt={title ?? ""}
          sizes={"(min-width: 768px) 50vw, 632px"}
          className=""
          fill
        />
      </AspectRatio>
    </div>
  );
};
