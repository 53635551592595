import { createQueryKeys } from "@lukemorales/query-key-factory";
import { AppLoader } from "loader/src";

const { getProfile } = AppLoader.userUseCase();

export const user = createQueryKeys("user", {
  details: {
    queryKey: null,
    queryFn: () => getProfile(),
  },
});
