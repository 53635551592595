import React from "react";

import { useLandingVideoGetSuspense as useLandingVideoGet } from "architecture/src/shared/api/endpoints/landing/landing";

import { AspectRatio } from "@/components/ui/surfaces/aspect-ratio";

import { Video } from "@/components/ui/data-display/video";

export const VideoJumbo: React.FC<{}> = () => {
  const { data: video } = useLandingVideoGet();

  if (!video.url) return null;

  return (
    <section className="container">
      <AspectRatio ratio={16 / 9} className="overflow-hidden rounded-max">
        <Video
          className="w-full h-full"
          src={video.url}
          poster={video.image ?? ""}
        />
      </AspectRatio>
    </section>
  );
};
