import { create } from "zustand";

type RefContainer = React.RefObject<HTMLDivElement>;

interface ScrollyTellingState {
  refSection?: RefContainer;
  setRefSection: (_refSection: RefContainer) => void;
}

const scrollyTellingStore = create<ScrollyTellingState>()((set) => ({
  refSection: undefined,
  setRefSection: (refSection) => set(() => ({ refSection })),
}));

export const useScrollyTellingStore = scrollyTellingStore;
