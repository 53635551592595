import Image from "next/image";
import Link from "next/link";

import { Typography } from "@/components/ui/data-display/typography";
import { Button } from "@/components/ui/inputs/button/button";

interface Item {
  image: string;
  title: string;
  description: string;
}

const list: Item[] = [
  {
    image: "/assets/images/hero.svg",
    title: "Cotiza",
    description: "Diligencia el formulario,  Dapper  valida tu información.",
  },
  {
    image: "/assets/images/hero.svg",
    title: "Ingresa",
    description:
      "A nuestra plataforma  a través de Google o tu correo electrónico.",
  },
  {
    image: "/assets/images/hero.svg",
    title: "Personaliza",
    description: "Dapper te ofrece  información de acuerdo a tus intereses.",
  },
  {
    image: "/assets/images/hero.svg",
    title: "Gestiona",
    description: "Encuentra información relevante para toma de deciciones.",
  },
];

export const HowItWorks = () => {
  return (
    <section className="flex flex-col items-center gap-10 px-10 py-20 rounded-3xl bg-muted">
      <Typography variant="h2" className="text-center">
        ¿Cómo funciona?
      </Typography>

      <ul className="flex">
        {list.map((item, index) => (
          <li
            key={index}
            className="flex flex-col items-center gap-2 p-10 text-center"
          >
            <Image src={item.image} alt={item.title} width={120} height={120} />
            <Typography variant="h5" color="primary">
              {item.title}
            </Typography>
            <Typography variant="caption">{item.description}</Typography>
          </li>
        ))}
      </ul>

      <Button asChild>
        <Link href="/">Cotiza tu Dapper</Link>
      </Button>
    </section>
  );
};
