import { AnimatePresence, Variants, motion as m } from "framer-motion";

import { Typography } from "@/components/ui/data-display/typography";
import { Stack } from "@/components/ui/layout/stack";
import { useMediaIsMobile, useScrollPositionIndex } from "@/src/hooks";
import { useStatsStore } from "../store";
import { ContentLanding } from "architecture/src/shared/domain";

type StatsItemsProps = Pick<ContentLanding, "info">;

type StatsItemProps = StatsItemsProps["info"][number] & {};

export const StatsItem: React.FC<StatsItemProps> = ({ title, description }) => {
  return (
    <Stack className="min-w-[16ch] max-w-[250px]">
      <Typography variant="h3" component="span">
        {description}
      </Typography>
      <Typography variant="body1" component="span">
        {title}
      </Typography>
    </Stack>
  );
};

export const StatsData: React.FC<ContentLanding> = ({ info }) => {
  const { refSection } = useStatsStore();
  const active = useScrollPositionIndex({
    length: info?.length,
    options: { target: refSection, offset: ["center end", "start start"] },
  });

  const isMobile = useMediaIsMobile();

  const MotionStack = m(Stack);

  const variants: Variants = {
    initial: {
      opacity: 0,
      y: "100%",
      rotateX: 100,
    },
    animate: {
      opacity: 1,
      y: "0%",
      rotateX: 0,
      // transition: {
      //   type: "spring",
      //   stiffness: 120,
      // },
    },
    exit: {
      opacity: 0,
      y: "-100%",
      rotateX: -100,
    },
  };

  const containerVariant: Variants = {
    animate: {
      transition: {
        staggerChildren: 0.2,
        type: "spring",
        stiffness: 120,
      },
    },
  };

  return isMobile ? (
    <MotionStack
      // layout
      direction="row"
      justify="spaceAround"
      className="perspective"
    >
      <AnimatePresence mode="popLayout">
        {info[active] && (
          <m.div
            key={`${info[active].title}-${active}`}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <StatsItem {...info[active]} />
          </m.div>
        )}
      </AnimatePresence>
    </MotionStack>
  ) : (
    <m.div
      variants={containerVariant}
      initial={"initial"}
      whileInView={"animate"}
    >
      <Stack direction="row" justify="spaceAround">
        {info?.map((item, index) => (
          <m.div variants={variants} key={`${item.title}-${index}`}>
            <StatsItem {...item} />
          </m.div>
        ))}
      </Stack>
    </m.div>
  );
};
