export * from "./banner";
export * from "./clients";
export * from "./hero";
export * from "./how-it-works";
export * from "./polifonia";
export * from "./scrolly-telling";
export * from "./stats";
export * from "./testimonial";
export * from "./video-jumbo";
export * from "./keywords";
