import * as z from "zod";

export const staticImageDataSchema = z.object({
  src: z.string(),
  height: z.number(),
  width: z.number(),
  blurDataURL: z.string().optional(),
  blurWidth: z.number().optional(),
  blurHeight: z.number().optional(),
});

export const imageSchema = z.object({
  src: z.string(),
  alt: z.string(),
});

export type Image = z.infer<typeof imageSchema>;
