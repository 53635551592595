import { CityDetail, TagDetail } from "architecture/src/shared/domain";
import { generateRandomNumberId } from "@/src/lib/utils/generate-random-id";

export const mapTagData = (tag: TagDetail) => {
  const id = (tag.id || generateRandomNumberId()).toString();
  return {
    id,
    label: tag.name,
  };
};

export const mapCityData = (city: CityDetail) => {
  const id = (city.id || generateRandomNumberId()).toString();
  return {
    value: id,
    label: city.name,
  };
};
