"use client";

import { ScrollyTellingVertical } from "./scrolly-telling.vertical";
import { useMediaIsTablet } from "@/src/hooks";
import { ScrollyTellingHorizontal } from "./scrolly-telling.horizontal";

export const ScrollyTelling: React.FC<{}> = () => {
  const isTablet = useMediaIsTablet();

  return isTablet ? <ScrollyTellingVertical /> : <ScrollyTellingHorizontal />;
};
