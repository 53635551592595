/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Dapper API
 * Public Documentation Dapper
 * OpenAPI spec version: v1
 */
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type { Banner } from "../../../domain/models/banner";
import type { Carrousel } from "../../../domain/models/carrousel";
import type { Companies } from "../../../domain/models/companies";
import type { ContentLanding } from "../../../domain/models/contentLanding";
import type { ContentSecondary } from "../../../domain/models/contentSecondary";
import type { ContentThird } from "../../../domain/models/contentThird";
import type { Error } from "../../../domain/models/error";
import type { Links } from "../../../domain/models/links";
import type { Testimonial } from "../../../domain/models/testimonial";
import type { VideoLanding } from "../../../domain/models/videoLanding";
import { apiClient } from "../../../../lib/api-client/api-client";
import type { ErrorType } from "../../../../lib/api-client/api-client";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const landingBannerGet = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<Banner>(
    { url: "/landing/banner", method: "GET", signal },
    options,
  );
};

export const getLandingBannerGetQueryKey = () => {
  return ["/landing/banner"] as const;
};

export const getLandingBannerGetQueryOptions = <
  TData = Awaited<ReturnType<typeof landingBannerGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof landingBannerGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingBannerGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingBannerGet>>
  > = ({ signal }) => landingBannerGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof landingBannerGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingBannerGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingBannerGet>>
>;
export type LandingBannerGetQueryError = ErrorType<Error>;

export const useLandingBannerGet = <
  TData = Awaited<ReturnType<typeof landingBannerGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof landingBannerGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingBannerGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLandingBannerGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof landingBannerGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingBannerGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingBannerGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingBannerGet>>
  > = ({ signal }) => landingBannerGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof landingBannerGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingBannerGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingBannerGet>>
>;
export type LandingBannerGetSuspenseQueryError = ErrorType<Error>;

export const useLandingBannerGetSuspense = <
  TData = Awaited<ReturnType<typeof landingBannerGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingBannerGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingBannerGetSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const landingCarrouselGet = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<Carrousel[]>(
    { url: "/landing/carrousel", method: "GET", signal },
    options,
  );
};

export const getLandingCarrouselGetQueryKey = () => {
  return ["/landing/carrousel"] as const;
};

export const getLandingCarrouselGetQueryOptions = <
  TData = Awaited<ReturnType<typeof landingCarrouselGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingCarrouselGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingCarrouselGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingCarrouselGet>>
  > = ({ signal }) => landingCarrouselGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof landingCarrouselGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingCarrouselGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingCarrouselGet>>
>;
export type LandingCarrouselGetQueryError = ErrorType<Error>;

export const useLandingCarrouselGet = <
  TData = Awaited<ReturnType<typeof landingCarrouselGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingCarrouselGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingCarrouselGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLandingCarrouselGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof landingCarrouselGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingCarrouselGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingCarrouselGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingCarrouselGet>>
  > = ({ signal }) => landingCarrouselGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof landingCarrouselGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingCarrouselGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingCarrouselGet>>
>;
export type LandingCarrouselGetSuspenseQueryError = ErrorType<Error>;

export const useLandingCarrouselGetSuspense = <
  TData = Awaited<ReturnType<typeof landingCarrouselGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingCarrouselGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingCarrouselGetSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const landingCompaniesGet = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<Companies[]>(
    { url: "/landing/companies", method: "GET", signal },
    options,
  );
};

export const getLandingCompaniesGetQueryKey = () => {
  return ["/landing/companies"] as const;
};

export const getLandingCompaniesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof landingCompaniesGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingCompaniesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingCompaniesGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingCompaniesGet>>
  > = ({ signal }) => landingCompaniesGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof landingCompaniesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingCompaniesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingCompaniesGet>>
>;
export type LandingCompaniesGetQueryError = ErrorType<Error>;

export const useLandingCompaniesGet = <
  TData = Awaited<ReturnType<typeof landingCompaniesGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingCompaniesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingCompaniesGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLandingCompaniesGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof landingCompaniesGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingCompaniesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingCompaniesGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingCompaniesGet>>
  > = ({ signal }) => landingCompaniesGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof landingCompaniesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingCompaniesGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingCompaniesGet>>
>;
export type LandingCompaniesGetSuspenseQueryError = ErrorType<Error>;

export const useLandingCompaniesGetSuspense = <
  TData = Awaited<ReturnType<typeof landingCompaniesGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingCompaniesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingCompaniesGetSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const landingContentSecondaryGet = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<ContentSecondary>(
    { url: "/landing/content/secondary", method: "GET", signal },
    options,
  );
};

export const getLandingContentSecondaryGetQueryKey = () => {
  return ["/landing/content/secondary"] as const;
};

export const getLandingContentSecondaryGetQueryOptions = <
  TData = Awaited<ReturnType<typeof landingContentSecondaryGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingContentSecondaryGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLandingContentSecondaryGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingContentSecondaryGet>>
  > = ({ signal }) => landingContentSecondaryGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof landingContentSecondaryGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingContentSecondaryGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingContentSecondaryGet>>
>;
export type LandingContentSecondaryGetQueryError = ErrorType<Error>;

export const useLandingContentSecondaryGet = <
  TData = Awaited<ReturnType<typeof landingContentSecondaryGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingContentSecondaryGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingContentSecondaryGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLandingContentSecondaryGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof landingContentSecondaryGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingContentSecondaryGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLandingContentSecondaryGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingContentSecondaryGet>>
  > = ({ signal }) => landingContentSecondaryGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof landingContentSecondaryGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingContentSecondaryGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingContentSecondaryGet>>
>;
export type LandingContentSecondaryGetSuspenseQueryError = ErrorType<Error>;

export const useLandingContentSecondaryGetSuspense = <
  TData = Awaited<ReturnType<typeof landingContentSecondaryGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingContentSecondaryGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLandingContentSecondaryGetSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const landingContentThirdGet = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<ContentThird>(
    { url: "/landing/content/third", method: "GET", signal },
    options,
  );
};

export const getLandingContentThirdGetQueryKey = () => {
  return ["/landing/content/third"] as const;
};

export const getLandingContentThirdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof landingContentThirdGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingContentThirdGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLandingContentThirdGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingContentThirdGet>>
  > = ({ signal }) => landingContentThirdGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof landingContentThirdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingContentThirdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingContentThirdGet>>
>;
export type LandingContentThirdGetQueryError = ErrorType<Error>;

export const useLandingContentThirdGet = <
  TData = Awaited<ReturnType<typeof landingContentThirdGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingContentThirdGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingContentThirdGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLandingContentThirdGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof landingContentThirdGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingContentThirdGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLandingContentThirdGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingContentThirdGet>>
  > = ({ signal }) => landingContentThirdGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof landingContentThirdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingContentThirdGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingContentThirdGet>>
>;
export type LandingContentThirdGetSuspenseQueryError = ErrorType<Error>;

export const useLandingContentThirdGetSuspense = <
  TData = Awaited<ReturnType<typeof landingContentThirdGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingContentThirdGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingContentThirdGetSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const landingContentsGet = (
  id: string,
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<ContentLanding>(
    { url: `/landing/content/${id}`, method: "GET", signal },
    options,
  );
};

export const getLandingContentsGetQueryKey = (id: string) => {
  return [`/landing/content/${id}`] as const;
};

export const getLandingContentsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof landingContentsGet>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof landingContentsGet>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingContentsGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingContentsGet>>
  > = ({ signal }) => landingContentsGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof landingContentsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingContentsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingContentsGet>>
>;
export type LandingContentsGetQueryError = ErrorType<Error>;

export const useLandingContentsGet = <
  TData = Awaited<ReturnType<typeof landingContentsGet>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof landingContentsGet>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingContentsGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLandingContentsGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof landingContentsGet>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof landingContentsGet>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingContentsGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingContentsGet>>
  > = ({ signal }) => landingContentsGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof landingContentsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingContentsGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingContentsGet>>
>;
export type LandingContentsGetSuspenseQueryError = ErrorType<Error>;

export const useLandingContentsGetSuspense = <
  TData = Awaited<ReturnType<typeof landingContentsGet>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof landingContentsGet>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingContentsGetSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const landingLinksGet = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<Links[]>(
    { url: "/landing/links", method: "GET", signal },
    options,
  );
};

export const getLandingLinksGetQueryKey = () => {
  return ["/landing/links"] as const;
};

export const getLandingLinksGetQueryOptions = <
  TData = Awaited<ReturnType<typeof landingLinksGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof landingLinksGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingLinksGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof landingLinksGet>>> = ({
    signal,
  }) => landingLinksGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof landingLinksGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingLinksGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingLinksGet>>
>;
export type LandingLinksGetQueryError = ErrorType<Error>;

export const useLandingLinksGet = <
  TData = Awaited<ReturnType<typeof landingLinksGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof landingLinksGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingLinksGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLandingLinksGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof landingLinksGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingLinksGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingLinksGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof landingLinksGet>>> = ({
    signal,
  }) => landingLinksGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof landingLinksGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingLinksGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingLinksGet>>
>;
export type LandingLinksGetSuspenseQueryError = ErrorType<Error>;

export const useLandingLinksGetSuspense = <
  TData = Awaited<ReturnType<typeof landingLinksGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingLinksGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingLinksGetSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const landingTestimonialsGet = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<Testimonial[]>(
    { url: "/landing/testimonials", method: "GET", signal },
    options,
  );
};

export const getLandingTestimonialsGetQueryKey = () => {
  return ["/landing/testimonials"] as const;
};

export const getLandingTestimonialsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof landingTestimonialsGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingTestimonialsGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLandingTestimonialsGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingTestimonialsGet>>
  > = ({ signal }) => landingTestimonialsGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof landingTestimonialsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingTestimonialsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingTestimonialsGet>>
>;
export type LandingTestimonialsGetQueryError = ErrorType<Error>;

export const useLandingTestimonialsGet = <
  TData = Awaited<ReturnType<typeof landingTestimonialsGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof landingTestimonialsGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingTestimonialsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLandingTestimonialsGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof landingTestimonialsGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingTestimonialsGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLandingTestimonialsGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof landingTestimonialsGet>>
  > = ({ signal }) => landingTestimonialsGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof landingTestimonialsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingTestimonialsGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingTestimonialsGet>>
>;
export type LandingTestimonialsGetSuspenseQueryError = ErrorType<Error>;

export const useLandingTestimonialsGetSuspense = <
  TData = Awaited<ReturnType<typeof landingTestimonialsGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingTestimonialsGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingTestimonialsGetSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const landingVideoGet = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<VideoLanding>(
    { url: "/landing/video", method: "GET", signal },
    options,
  );
};

export const getLandingVideoGetQueryKey = () => {
  return ["/landing/video"] as const;
};

export const getLandingVideoGetQueryOptions = <
  TData = Awaited<ReturnType<typeof landingVideoGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof landingVideoGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingVideoGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof landingVideoGet>>> = ({
    signal,
  }) => landingVideoGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof landingVideoGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingVideoGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingVideoGet>>
>;
export type LandingVideoGetQueryError = ErrorType<Error>;

export const useLandingVideoGet = <
  TData = Awaited<ReturnType<typeof landingVideoGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof landingVideoGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingVideoGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLandingVideoGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof landingVideoGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingVideoGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLandingVideoGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof landingVideoGet>>> = ({
    signal,
  }) => landingVideoGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof landingVideoGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LandingVideoGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof landingVideoGet>>
>;
export type LandingVideoGetSuspenseQueryError = ErrorType<Error>;

export const useLandingVideoGetSuspense = <
  TData = Awaited<ReturnType<typeof landingVideoGet>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof landingVideoGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLandingVideoGetSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
