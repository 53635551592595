"use client";

import { wrap } from "@motionone/utils";
import {
  motion,
  useAnimationFrame,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
  useVelocity,
} from "framer-motion";
import { useRef, useState } from "react";

import Ribbon, { type IRibbon } from "./ribbon";

export interface IRibbonWithLoop extends IRibbon {
  baseVelocity?: number;
  min?: number;
  max?: number;
  velocityOnHover?: number;
}

export const withLoop = (Component: React.FC<IRibbon>) => {
  const RibbonWithLoop = ({
    children,
    baseVelocity = 1,
    min = -21,
    max = -120,
    velocityOnHover = 1,
    ...others
  }: IRibbonWithLoop) => {
    const [speed, setSpeed] = useState(baseVelocity);
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
      damping: 50,
      stiffness: 400,
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
      clamp: false,
    });

    /**
     * This is a magic wrapping for the length of the text - you
     * have to replace for wrapping that works for you or dynamically
     * calculate
     */

    const x = useTransform(baseX, (v) => `${wrap(min, max, v)}%`);

    const directionFactor = useRef<number>(1);
    useAnimationFrame((t, delta) => {
      let moveBy = directionFactor.current * speed * (delta / 1000);

      /**
       * This is what changes the direction of the scroll once we
       * switch scrolling directions.
       */
      if (velocityFactor.get() < 0) {
        directionFactor.current = -1;
      } else if (velocityFactor.get() > 0) {
        directionFactor.current = 1;
      }

      moveBy += directionFactor.current * moveBy * velocityFactor.get();
      // console.log('moveBy', moveBy);
      baseX.set(baseX.get() + moveBy);
    });

    return (
      <Component {...others}>
        <div className="flex overflow-hidden flex-nowrap whitespace-nowrap">
          <motion.div
            className="flex items-center"
            style={{ x }}
            onHoverStart={() => setSpeed(velocityOnHover)}
            onHoverEnd={() => setSpeed(baseVelocity)}
          >
            {Array.from({ length: 4 }).map((_, i) => (
              <span key={i} className="flex flex-none">
                {children}
              </span>
            ))}
          </motion.div>
        </div>
      </Component>
    );
  };

  RibbonWithLoop.displayName = `withLoop(${Component.displayName})`;

  return RibbonWithLoop;
};

export const RibbonWithLoop = withLoop(Ribbon);

export default RibbonWithLoop;
