import { AuthApiAdapter, AuthUseCase } from "architecture/features/auth";
import { HomeApiAdapter, HomeUseCase } from "architecture/features/home";
import { UserApiAdapter, UserUseCase } from "architecture/features/user";

namespace AppLoader {
  const homeAdapter = new HomeApiAdapter();
  const authenticationAdapter = new AuthApiAdapter();
  const userAdapter = new UserApiAdapter();

  const authentication = new AuthUseCase(authenticationAdapter);
  const home = new HomeUseCase(homeAdapter);
  const user = new UserUseCase(userAdapter);

  /**
   * Return the authentication use case
   * @returns {AuthUseCase} The authentication use case
   */
  export function authUseCase() {
    return authentication;
  }

  export function homeUseCase() {
    return home;
  }

  export function userUseCase() {
    return user;
  }
}

export { AppLoader };
