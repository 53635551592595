import { LoginGoogle } from "../../../src/shared/domain/models/loginGoogle";
import { LoginRequest } from "../../../src/shared/domain/models/loginRequest";
import { SendEmailRestorePasswordRequest } from "../../../src/shared/domain/models/sendEmailRestorePasswordRequest";
import type { AuthAdapter, AuthRepository } from "../domain";

class AuthUseCase implements AuthRepository {
  private authAdapter: AuthAdapter;

  constructor(_authAdapter: AuthAdapter) {
    this.authAdapter = _authAdapter;
  }

  login = (loginRequest: LoginRequest) => {
    return this.authAdapter.login(loginRequest);
  };

  signInWithGoogle = (token: LoginGoogle) => {
    return this.authAdapter.signInWithGoogle(token);
  };

  sendEmailRestorePassword = (_email?: SendEmailRestorePasswordRequest) => {
    return this.authAdapter.sendEmailRestorePassword();
  };
}

export { AuthUseCase };
