import { Typography } from "@/components/ui/data-display/typography";
import { Stack } from "@/components/ui/layout/stack";

import { Card } from "@/components/ui/surfaces/card";
import { PublicListeningSpeaker } from "./components";
import { useLandingContentSecondaryGetSuspense } from "architecture/src/shared/api/endpoints/landing/landing";
import Image from "next/image";

export const PublicListening: React.FC<{}> = () => {
  const { data: polifonia } = useLandingContentSecondaryGetSuspense();

  return (
    <section className="my-10">
      <div className="container mx-auto">
        <Card
          rounded="max"
          variant="primary"
          className="relative p-10 pt-6 text-center md:py-20"
        >
          <Stack spacing="md" className="max-w-4xl mx-auto relative">
            <Stack spacing="xs">
              <Typography variant="h3">{polifonia.title}</Typography>
              <Typography variant="body2">{polifonia.summary}</Typography>
            </Stack>

            {/* Speaker */}
            <PublicListeningSpeaker {...polifonia} />
            <Stack
              align="center"
              className="md:items-end w-full mx-auto md:-mt-8 absolute h-[75px] bottom-[-35px]"
            >
              <Image
                src={polifonia.image2 ?? ""}
                alt={polifonia.title ?? ""}
                width={300}
                height={75}
                className=" max-w-auto"
              />
            </Stack>
          </Stack>
          {/* Avatars */}
        </Card>
      </div>
    </section>
  );
};
