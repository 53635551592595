import { Transition, Variants, motion as m } from "framer-motion";

import { Style } from "architecture/src/shared/domain/types";
import { cn } from "@/src/lib/utils";

type WithScroll = {
  style?: Style;
  variants?: Variants | undefined;
  classNameContainer?: string;
  transition?: Transition | undefined;
};

const withScroll = <T extends object>(Component: React.ComponentType<T>) => {
  const ComponentWithScroll: React.FC<T & WithScroll> = ({
    style,
    variants,
    classNameContainer,
    transition,
    ...hocProps
  }) => {
    return (
      <div
        className={cn(
          "overflow-hidden perspective p-2.5 -m-2.5",
          classNameContainer,
        )}
      >
        <m.div style={style} variants={variants} transition={transition}>
          <Component {...(hocProps as T)} />
        </m.div>
      </div>
    );
  };

  ComponentWithScroll.displayName = `withScroll(${Component.displayName})`;

  return ComponentWithScroll;
};

export { withScroll };
