import Image from "next/image";

import { BannerInfo } from "architecture/src/shared/domain";

import { Typography } from "@/components/ui/data-display/typography";
import { Card } from "@/components/ui/surfaces/card";

// Icons
import FileLikeIcon from "public/static/icons/file-like-icon.svg";
import LawIcon from "public/static/icons/law-icon.svg";
import LocationIcon from "public/static/icons/location-icon.svg";
import PlantIcon from "public/static/icons/plant-icon.svg";

const icon = {
  law: LawIcon,
  file: FileLikeIcon,
  plant: PlantIcon,
  location: LocationIcon,
};

type CardInfoProps = BannerInfo;

export const CardInfo: React.FC<CardInfoProps> = ({ image, title }) => {
  return (
    <Card variant="secondary" shadow="xl">
      <div className="flex items-center gap-2 px-4 py-3 md:p-6 md:gap-6">
        <Image
          src={image ?? ""}
          alt={title ?? ""}
          className="w-6 h-6 md:w-8 md:h-8"
          width={48}
          height={48}
        />
        <Typography variant="subtitle2">{title}</Typography>
      </div>
    </Card>
  );
};
