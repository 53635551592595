import { Style } from "architecture/src/shared/domain/types";

import { TypographyWithScroll } from "@/components/ui/data-display/typography";

export const ScrollyTellingDescription: React.FC<{
  children: string;
  style?: Style;
}> = ({ children, style }) => {
  return (
    <TypographyWithScroll
      component="span"
      style={{
        y: style?.y,
        rotateX: style?.rotateX,
      }}
      className="text-base md:text-lg"
    >
      <div dangerouslySetInnerHTML={{ __html: children }} />
    </TypographyWithScroll>
  );
};
