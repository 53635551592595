import * as React from "react";

import { cn } from "@/src/lib/utils";
import Image from "next/image";

import PlayIcon from "public/static/images/shared/play-circle.png";
import PauseIcon from "public/static/images/shared/pause-circle.png";

import { Stack } from "@/components/ui/layout/stack";

export interface VideoProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {}

function Video({ className, ...props }: VideoProps) {
  const vidRef = React.useRef<HTMLVideoElement>(null);
  const [isPlayed, setIsPlayed] = React.useState<boolean>(false);

  function handlePausePlay() {
    if (vidRef.current) {
      if (isPlayed) {
        vidRef.current.pause();
        setIsPlayed(false);
      } else {
        vidRef.current.play().then(() => {
          setIsPlayed(true);
        });
      }
    }
  }

  return (
    <Stack
      align="center"
      justify="center"
      className={cn("w-full h-full relative", className)}
    >
      <video
        className="w-full h-full object-cover"
        ref={vidRef}
        controls={isPlayed ? true : props.controls}
        {...props}
      />
      <Image
        src={isPlayed ? PauseIcon : PlayIcon}
        onClick={handlePausePlay}
        alt="Play"
        className={`absolute left-0 right-0 mx-auto cursor-pointer ${
          isPlayed && "opacity-0"
        } `}
        width={120}
        height={120}
      />
    </Stack>
  );
}
Video.displayName = "Video";

export { Video };
