"use client";

import Link from "next/link";
import React from "react";

import { Banner } from "architecture/src/shared/domain";

import { Typography } from "@/components/ui/data-display/typography";
import { Button } from "@/components/ui/inputs/button/button";
import { Stack } from "@/components/ui/layout/stack";

type HeroHeaderProps = Pick<Banner, "title" | "summary">;

export const HeroHeader: React.FC<HeroHeaderProps> = ({ title, summary }) => {
  return (
    <Stack direction="column" spacing="md" component="header">
      <Typography
        className="[&>*]:text-primary"
        dangerouslySetInnerHTML={{ __html: title ?? "" }}
        variant="h1"
      />

      <Typography color="muted" variant="h5" component="p">
        {summary}
      </Typography>

      <div className="flex gap-4 pt-2">
        <Button asChild>
          <Link href="/auth/register">Únete a Dapper</Link>
        </Button>
      </div>
    </Stack>
  );
};
