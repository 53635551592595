import Link from "next/link";

import { useLandingContentsGetSuspense as useLandingContentsGet } from "architecture/src/shared/api/endpoints/landing/landing";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/data-display/avatar";
import { Typography } from "@/components/ui/data-display/typography";
import { Button } from "@/components/ui/inputs/button/button";
import { Stack } from "@/components/ui/layout/stack";
import { Card } from "@/components/ui/surfaces/card";

export const Banner: React.FC<{}> = () => {
  const { data: content } = useLandingContentsGet("2");

  if (!content) return null;

  return (
    <section className="my-10 mt-20">
      <div className="container mx-auto">
        <Card
          rounded="max"
          variant="secondary"
          className="relative p-10 pt-0 text-center md:pb-20"
        >
          {/* Text context */}
          <Stack spacing="sm" className="max-w-[975px] mx-auto">
            <div className="mb-6 -mt-10 md:mb-10">
              {/* Avatar list */}
              {content.info ? (
                <Stack direction="row" justify="spaceAround">
                  {content.info.map(({ image, title }) => (
                    <Stack key={title} className="" align="center">
                      <Avatar className="w-20 h-20 border-2 shadow-lg rounded-dot">
                        <AvatarImage src={image ?? ""} alt={title} />
                        <AvatarFallback>{title}</AvatarFallback>
                      </Avatar>
                      <Typography
                        variant="subtitle1"
                        className="max-w-44 line-clamp-2 text-primary"
                      >
                        {title}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              ) : null}
            </div>
            <Stack spacing="lg">
              <Typography
                className="[&>*]:text-primary"
                variant="h3"
                dangerouslySetInnerHTML={{ __html: content.title ?? "" }}
              />

              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: content.summary ?? "" }}
              />

              {content.textButton ? (
                <div>
                  <Button asChild>
                    <Link href={content.urlButton ?? ""}>
                      {content.textButton}
                    </Link>
                  </Button>
                </div>
              ) : null}
            </Stack>
          </Stack>
        </Card>
      </div>
    </section>
  );
};
